import { Injectable, signal } from '@angular/core';
import { AlertType } from '../components/alert/alert.component';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

interface State {
	content: string;
	type: AlertType;
	timeout: ReturnType<typeof setTimeout>;
}

const initialState: State = {
	content: '',
	type: 'alert',
	timeout: null
};

export const ToasterStore = signalStore(
	{ providedIn: 'root' },
	withState(initialState),
	withMethods((store) => ({
		alert(message: string, duration = 5000) {
			this.toast(message, 'alert', duration);
		},
		error(message: string, duration = 5000) {
			this.toast(message, 'error', duration);
		},
		toast(message: string, type: AlertType, duration = 5000) {
			const timeout = setTimeout(() => {
				this.dismiss();
			}, duration);
			patchState(store, (state) => ({ ...state, content: message, type, timeout }));
		},
		dismiss() {
			patchState(store, (state) => ({ ...state, content: '' }));
			clearTimeout(store.timeout());
		}
	}))
);
