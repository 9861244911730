import { Component, inject } from '@angular/core';
import { ToasterStore } from '../../services/toaster.service';
import { AlertComponent } from '../alert/alert.component';

@Component({
	selector: 'rc-toast',
	templateUrl: './toast.component.html',
	styleUrls: ['./toast.component.scss'],
	standalone: true,
	imports: [AlertComponent],
})
export class ToastComponent {
	toaster = inject(ToasterStore);
}
