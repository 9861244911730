import { computed, inject } from '@angular/core';
import { patchState, signalStore, withComputed, withHooks, withState } from '@ngrx/signals';
import { UserStore } from '@rc/rev-cyclone/user';
import { Dentist, Practice } from '@rc/typings/practice';
import { toObservable } from '@angular/core/rxjs-interop';
import { combineLatest, filter, map, Observable, of, switchMap, tap } from 'rxjs';
import { collection, CollectionReference, doc, docData, DocumentReference, Firestore, onSnapshot, query, QuerySnapshot, where } from '@angular/fire/firestore';
import { mapArray } from 'ngxtension/map-array';

class State {
	practice: Practice;

	dentists: Dentist[];

	loaded: boolean;
}

const initialState: State = {
	practice: {
		name: '',
		practiceId: '',
		zip: '',
		users: [],
		dentistIds: []
	},
	dentists: [],
	loaded: false
};

export const PracticeStore = signalStore(
	{ providedIn: 'root' },
	withState(initialState),
	withHooks({
		async onInit(store, userStore = inject(UserStore), firestore = inject(Firestore)) {
			toObservable(userStore.user).pipe(
				filter((u) => !!u),
				switchMap(() => {
					const practice = userStore.user().practiceId;
					return docData<Practice>(doc(collection(firestore, '/practices') as CollectionReference<Practice>, practice) as DocumentReference<Practice>, { idField: 'practiceId' });
				}),
				tap((practice) => patchState(store, (state) => ({ ...state, practice: new Practice(practice) }))),
				switchMap((practice) => {
					if (!practice.dentistIds.length) return of([]);
					return combineLatest(practice.dentistIds.map((dentistId) => docData<Dentist>(doc(collection(firestore, '/dentists') as CollectionReference<Dentist>, dentistId) as DocumentReference<Dentist>, { idField: 'npi' })));
				}),
				map((dentists) => dentists.map((dentist) => new Dentist(dentist)))
				// mapArray((dentist) => new Dentist(dentist))
			).subscribe((dentists: Dentist[]) => {
				if (!store.dentists() || dentists.map((d) => d.npi) !== store.dentists().map((d) => d.npi)) {
					patchState(store, (state) => ({ ...state, dentists, loaded: true }));
				}
			});
		}
	}),
	withComputed(({ practice }, user = inject(UserStore)) => ({ hasAccess: computed(() => practice.users().some((u) => u.userId === user.user().userId)) })),
);
