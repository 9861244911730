import { Component, input, output } from '@angular/core';

import { MatIcon } from '@angular/material/icon';

export type AlertType = 'alert' | 'error';

@Component({
	selector: 'rc-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss'],
	standalone: true,
	imports: [MatIcon],
})
export class AlertComponent {

	type = input<AlertType>('alert');

	dismissable = input(true);

	dismiss = output();

	constructor() {}
}
